export const MAX_ATTACHMENTS_SIZE = 10000000

export const ACCEPTED_FILE_EXTENSIONS = [
  // PNG
  '.png',
  'image/png',

  // JPG
  '.jpg',
  'image/jpg',
  '.jpeg',
  'image/jpeg',

  // HEIF
  '.heic',
  'image/heic',
  '.heif',
  'image/heif',

  // PDF
  '.pdf',
  'application/pdf',

  // MP4
  '.mp4',
  'video/mp4',
  'audio/mp4',
  'application/mp4',

  // ZIP
  '.zip',
  'application/zip',
].join(',')
