<template>
  <div>
    <InWarrantyInfoBlock v-if="isInWarranty" :claim />

    <OutOfWarrantyInfoBlock
      v-if="!isInWarranty"
      :customer-issues="claim.customerIssues"
      :label-return="claim.labelReturn"
      :warranty-state="claim.declaredWarrantyState"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import type { GetCareFolderBackcareAgentDiscussion } from '@backmarket/http-api/src/api-specs-claim-management/customer-request/types/claim'
import { isEmpty } from '@backmarket/utils/object/isEmpty'

import InWarrantyInfoBlock from './components/InWarrantyInfoBlock/InWarrantyInfoBlock.vue'
import OutOfWarrantyInfoBlock from './components/OutOfWarrantyInfoBlock/OutOfWarrantyInfoBlock.vue'

const props = defineProps<{
  claim: GetCareFolderBackcareAgentDiscussion.Claim
}>()

const isInWarranty = computed(
  () =>
    isEmpty(props.claim.declaredWarrantyState) ||
    props.claim.declaredWarrantyState === 'IN_WARRANTY',
)
</script>
