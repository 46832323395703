<template>
  <!-- The product description is sent by the API as HTML. -->
  <!-- eslint-disable vue/no-v-html -->
  <!-- nosemgrep: javascript.vue.security.audit.xss.templates.avoid-v-html.avoid-v-html -->
  <div
    class="whitespace-pre-line break-words"
    :class="$style.forceWordBreak"
    v-html="parsedContent"
  />
  <!-- eslint-enable -->
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { isEmpty } from '@backmarket/utils/object/isEmpty'

const props = defineProps<{ content: string; shouldFormatLinks: boolean }>()

const parsedContent = computed(() => {
  if (isEmpty(props.content)) return ''

  // First replace all line breaks.
  const wrappedContent = props.content.replace(/<br ?\/?>/gi, '\n')

  // Then escape any HTML that may be in the message to avoid injection.
  let cleanedContent = wrappedContent
    .replace(/</gi, '&lt;')
    .replace(/>/gi, '&gt;')

  if (props.shouldFormatLinks) {
    // However, don't escape HTML links (`<a />` tags) from Backmarket's
    // messages.
    cleanedContent = cleanedContent.replace(
      /&lt;a(.*)&gt;(.*)&lt;\/a&gt;?/gi,
      '<a$1>$2</a>',
    )
  }

  return (
    cleanedContent
      // Then parse any "http" or "https" starting link that is not inside
      // of a `<a />` tag and convert them to clickable links.
      .replace(
        /(^|[^"])(https?:\/\/[^\s]+)/gi,
        '$1<a href="$2" target="_blank" rel="noreferrer noopener">$2</a>',
      )
  )
})
</script>

<style module>
a {
  text-decoration: underline;
}
.forceWordBreak {
  word-break: break-word;
}
</style>
