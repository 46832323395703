<template>
  <div
    v-for="(message, index) in liveChat.messages"
    :key="message.messageId"
    class="flex w-full flex-col items-center space-y-16"
    data-qa="live-chat-message"
  >
    <Timestamp
      v-if="isMessageNewDay(message, index)"
      :date="message.creationDate"
    />
    <Message
      :id="`message-${message.messageId}`"
      ref="messageContainer"
      :care-folder-id
      class="w-full p-16 lg:max-w-[57rem]"
      :message
      @intersect="setMessageVisibility"
    />
  </div>
  <div
    v-if="unseenMessageNumber && unseenMessageNumber > 0"
    class="fixed bottom-[1rem] z-20 flex w-full justify-center"
  >
    <RevButton
      class="animate-bounce"
      size="small"
      variant="primary"
      @click="scrollToFirstUnseenMessage"
    >
      {{ i18n(translations.newMessages, { number: unseenMessageNumber }) }}
    </RevButton>
  </div>
</template>

<script setup lang="ts">
import { computed, inject } from 'vue'

import type { GetCareFolder } from '@backmarket/http-api/src/api-specs-claim-management/care-folder/types/care-folder'
import type { GetLiveChat } from '@backmarket/http-api/src/api-specs-claim-management/care-folder/types/live-chat'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButton } from '@ds/components/Button'
import { useTitle } from '@vueuse/core'

import { areDatesSameDay } from '../../Conversation.utils'
import { SCROLL_CONTAINER_INJECTION_KEY } from '../../symbols'
import Message from '../Message/Message.vue'
import Timestamp from '../Timestamp/Timestamp.vue'

import translations from './LiveChatMessages.translations'

const props = defineProps<{
  careFolderPublicId: GetCareFolder.CareFolder['publicId']
  claimMessages: GetLiveChat.Message[]
  careFolderId: GetCareFolder.CareFolder['customerRequestId']
  isLastMessageVisible: boolean
  liveChat: GetLiveChat.Response
  firstUnseenMessageId?: string
  unseenMessageNumber?: number
  setMessageVisibility: ({
    messageId,
    hasBeenSeen,
  }: {
    messageId: number
    hasBeenSeen: boolean
  }) => void
  scrollToLastMessage: ({ smoothly }: { smoothly?: boolean }) => void
}>()

const i18n = useI18n()

const scrollContainerRef = inject(SCROLL_CONTAINER_INJECTION_KEY, null)

const headTitle = computed(() => {
  return props.unseenMessageNumber && props.unseenMessageNumber > 0
    ? i18n(translations.newMessages, { number: props.unseenMessageNumber })
    : i18n(translations.title)
})

function isMessageNewDay(currentMessage: GetLiveChat.Message, index: number) {
  if (index === 0) return false
  const previousMessage = props.liveChat.messages[index - 1]

  return !areDatesSameDay(
    currentMessage.creationDate,
    previousMessage.creationDate,
  )
}

function scrollToFirstUnseenMessage() {
  if (
    scrollContainerRef === null ||
    scrollContainerRef.value === null ||
    !props.firstUnseenMessageId
  )
    return

  scrollContainerRef.value
    ?.querySelector(`#message-${props.firstUnseenMessageId}`)
    ?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
}

useTitle(headTitle)
</script>
