<template>
  <ClientOnly>
    <div class="body-2-bold m-16 flex w-full items-center gap-4">
      <RevDivider class="grow" />
      <RevTag
        :label="
          i18n.date(new Date(date), {
            weekday: 'long',
            day: 'numeric',
            month: 'long',
            year: '2-digit',
          })
        "
        variant="secondary"
      />
      <RevDivider class="grow" />
    </div>
  </ClientOnly>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevDivider } from '@ds/components/Divider'
import { RevTag } from '@ds/components/Tag'

defineProps<{ date: string }>()

const i18n = useI18n()
</script>
