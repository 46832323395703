<template>
  <RevAvatar
    :class="className"
    :label="displayName"
    size="medium"
    :thumbnail="icon"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { RevAvatar } from '@ds/components/Avatar'
import { IconBackMarketAvatar } from '@ds/icons/IconBackMarketAvatar'
import { IconShop } from '@ds/icons/IconShop'

const props = defineProps<{
  senderType: 'seller' | 'backmarket' | 'customer' | null
  displayName: string
}>()

const className = computed(() => {
  if (props.senderType === 'customer') return 'mood-purple'
  if (props.senderType === 'seller') return 'mood-emerald'

  return 'mood-blush'
})

const icon = computed(() => {
  if (props.senderType === 'backmarket') return IconBackMarketAvatar
  if (props.senderType === 'seller') return IconShop

  return undefined
})
</script>
