import { createHttpEndpoint } from '../../utils'

import type { GetCareFolder } from './types/care-folder'
import type { GetLiveChat } from './types/live-chat'

/**
 * View to retrieve a Care Folder document.
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/customer-request-care-folder-internal-api/definition#tag/Care-Folder/operation/getCareFolder}
 */
export const getCareFolder = createHttpEndpoint<GetCareFolder.Response>({
  method: 'GET',
  operationId: 'fetchCareFolder',
  path: '/bm/care-folders/:careFolderId',
})

/**
 * View to terminate a live chat service request
 *
 * @see {@link https://https://devportal.backmarket.io/catalog/default/api/customer-request-care-folder-internal-api/definition#/operation/postLiveChatTerminate }
 */
export const postLiveChatTerminate = createHttpEndpoint({
  method: 'POST',
  operationId: 'terminateLiveChat',
  path: '/bm/care-folders/:careFolderId/live-chat/terminate',
})

/**
 * View to retrieve a Care Folder document.
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/customer-request-care-folder-internal-api/definition#/Live%20Chat/getLiveChat}
 */
export const getLiveChat = createHttpEndpoint<GetLiveChat.Response>({
  method: 'GET',
  operationId: 'getLiveChat',
  path: '/bm/care-folders/:careFolderPublicId/live-chat',
})
