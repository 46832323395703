export default {
  cta: {
    id: 'asp_read_only_conversation_info_block_cta',
    defaultMessage: 'Get help',
  },
  description: {
    id: 'asp_read_only_conversation_info_block_description',
    defaultMessage:
      'We’re switching support platforms, so we won’t be able to see new messages here. If you need more help, go to “Orders” > “Get help” and we’ll get right on it.',
  },
  title: {
    id: 'asp_read_only_conversation_info_block_title',
    defaultMessage: 'Read-only conversation',
  },
}
