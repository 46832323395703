import { ref } from 'vue'

import { defineStore } from 'pinia'

export const useCustomerConversationStore = defineStore(
  'customer-conversation',
  () => {
    const isInformativeBannerDisplayed = ref(true)

    function closeInformativeBannerDisplayed() {
      isInformativeBannerDisplayed.value = false
    }

    function displayInformativeBanner() {
      isInformativeBannerDisplayed.value = true
    }

    return {
      isInformativeBannerDisplayed,
      closeInformativeBannerDisplayed,
      displayInformativeBanner,
    }
  },
)
