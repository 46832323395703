<template>
  <RevInfoBlock
    :content="i18n(translations.description)"
    :cta="i18n(translations.cta)"
    :icon="IconCrossInCircle"
    :title="i18n(translations.title)"
    variant="danger"
    @click="handleClick"
  />
</template>

<script lang="ts" setup>
import { navigateTo } from '#imports'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevInfoBlock } from '@ds/components/InfoBlock'
import { IconCrossInCircle } from '@ds/icons/IconCrossInCircle'

import { CUSTOMER_CARE } from '@/scopes/customer-care/route-names'

import translations from './ReadOnlyConversation.translations'
import type { ReadOnlyConversationPropsType } from './ReadOnlyConversation.types'

const props = defineProps<ReadOnlyConversationPropsType>()

const i18n = useI18n()

function handleClick() {
  return navigateTo({
    name: CUSTOMER_CARE.DIAGNOSIS.SELF,
    params: {
      orderlineId: props.orderlineId,
    },
  })
}
</script>
