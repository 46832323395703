<template>
  <div class="bg-surface-default-mid flex h-lvh flex-col">
    <div
      class="bg-surface-default-low border-static-default-low sticky top-0 w-full border-b"
    >
      <Header
        :care-folder-id="data?.careFolder.customerRequestId"
        :is-live-chat-active="shouldDisplayLiveChat"
        :pending="isConversationLoading"
        :status="liveChat.status"
      />
      <Discussion />

      <ErrorInfoBlock
        v-if="conversationError"
        class="mt-16"
        data-qa="error-banner"
        :error-message="conversationError?.message"
        @try-again="refresh"
      />
    </div>

    <div class="flex w-full grow overflow-hidden">
      <div class="flex grow flex-col">
        <div
          ref="scrollContainer"
          class="flex w-full grow flex-col overflow-x-hidden overflow-y-scroll"
        >
          <Skeleton v-if="isConversationLoading" :messageSkeletonNum="4" />

          <div v-if="data && !isConversationLoading" class="grow pt-16">
            <div
              v-for="(claim, index) in data.claims"
              :key="claim.claimId"
              class="flex w-full flex-col items-center space-y-16 px-24"
            >
              <Timestamp :date="claim.creationDate" />
              <ClaimInfo :claim class="w-full lg:max-w-[57rem]" />

              <LiveChatMessages
                v-if="
                  data.careFolder?.publicId &&
                  shouldDisplayLiveChat &&
                  isLastClaim(index)
                "
                :care-folder-id="data.careFolder.customerRequestId"
                :care-folder-public-id="data.careFolder.publicId"
                :claim-messages="liveChat.messages"
                :first-unseen-message-id
                :is-last-message-visible
                :live-chat
                :scroll-to-last-message
                :set-message-visibility
                :unseen-message-number
              />
              <ClaimMessages
                v-else
                :care-folder-id="data.careFolder.customerRequestId"
                :messages="claim.messages"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="data?.careFolder"
      id="messageFormContainer"
      ref="messageFormContainer"
      class="bg-static-default-low border-static-default-low rounded-lg relative m-24 border p-24"
    >
      <ReadOnlyConversation
        v-if="isReadOnlyConversation"
        data-qa="read-only-conversation-block"
        data-test="read-only-conversation-block"
        :orderline-id="data.careFolder.orderline.orderlineId"
      />

      <MessageForm
        v-if="!isReadOnlyConversation"
        :key="formRenderKey"
        :care-folder="data.careFolder"
        data-qa="message-form"
        data-test="message-form"
        :is-loading="isMessageSending || isConversationLoading"
        :name="FORM_NAME"
        :submit-label="i18n(translations.submit)"
        :zone="TRACKING_ZONES.SEND_MESSAGE_FLOW"
        @submit="sendMessage"
      />
    </div>
  </div>
  <div id="toast-wrapper">
    <TheToast to="#toast-wrapper" />
  </div>
</template>

<script setup lang="ts">
import { useRouter, useRuntimeConfig } from '#imports'
import {
  computed,
  nextTick,
  onMounted,
  onUnmounted,
  provide,
  ref,
  watch,
} from 'vue'

import { handleFileUpload } from '@backmarket/front-upload'
import { RESOLUTION_OPTION_TYPE_ENUM } from '@backmarket/http-api/src/api-specs-resolution-engine/types/common'
import { getHttpBaseUrl } from '@backmarket/nuxt-module-http/getHttpBaseUrl'
import { useHttpFetch } from '@backmarket/nuxt-module-http-v2/useHttpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import TheToast from '@backmarket/nuxt-module-toast/TheToast.vue'
import { useTheToast } from '@backmarket/nuxt-module-toast/useTheToast'
import { isEmpty } from '@backmarket/utils/object/isEmpty'

import { ROUTE_NAMES } from '@/scopes/dashboard/constants'

import { FORM_NAME, TRACKING_ZONES } from './Conversation.constants'
import ClaimInfo from './components/ClaimInfo/ClaimInfo.vue'
import ClaimMessages from './components/ClaimMessages/ClaimMessages.vue'
import Discussion from './components/Discussion/Discussion.vue'
import ErrorInfoBlock from './components/ErrorInfoBlock/ErrorInfoBlock.vue'
import Header from './components/Header/Header.vue'
import LiveChatMessages from './components/LiveChatMessages/LiveChatMessages.vue'
import translations from './components/Message/Message.translations'
import MessageForm from './components/MessageForm/MessageForm.vue'
import ReadOnlyConversation from './components/ReadOnlyConversation/ReadOnlyConversation.vue'
import Skeleton from './components/Skeleton/Skeleton.vue'
import Timestamp from './components/Timestamp/Timestamp.vue'
import { useCustomerConversation } from './composables/useCustomerConversation'
import { useLiveChat } from './composables/useGetLiveChat'
import { SCROLL_CONTAINER_INJECTION_KEY } from './symbols'

const { FF_ENABLED_INTERCOM_ROUTE_CUSTOMER_CONVERSATION_BY_COUNTRY } =
  useRuntimeConfig().public

const i18n = useI18n()
const logger = useLogger()
const { market } = useMarketplace()
const router = useRouter()
const $httpFetch = useHttpFetch()

const messageFormContainer = ref<HTMLElement | null>(null)
const scrollContainer = ref<HTMLElement | null>(null)
const formRenderKey = ref<number>(0)
const isMessageSending = ref(false)
const isFirstLoad = ref(true)

provide(SCROLL_CONTAINER_INJECTION_KEY, scrollContainer)

const {
  data,
  pending: isConversationLoading,
  error: conversationError,
  pushMessage,
  refresh,
} = useCustomerConversation()

const { openErrorToast, openSuccessToast } = useTheToast()

// Scroll to the bottom of the claims container to see the last message of the last claim first
async function scrollToLastMessage({ smoothly }: { smoothly?: boolean } = {}) {
  if (scrollContainer.value?.scrollTo) {
    await nextTick()
    scrollContainer.value.scrollTo({
      top: scrollContainer.value.scrollHeight,
      behavior: smoothly ? 'smooth' : 'instant',
    })
  }
}

const isIntercomEnabled = computed(() =>
  FF_ENABLED_INTERCOM_ROUTE_CUSTOMER_CONVERSATION_BY_COUNTRY?.includes(
    market.countryCode,
  ),
)

const isLastMessageVisible = computed(() => {
  if (scrollContainer.value === null) return false
  if (messageFormContainer.value === null) return false

  // Check if the current viewport allow to see the last message, with a 140px offset margin
  return (
    scrollContainer.value.scrollTop + scrollContainer.value.clientHeight >=
    scrollContainer.value.scrollHeight -
      messageFormContainer.value.clientHeight -
      140
  )
})

function handleNewMessageReceived() {
  if (isLastMessageVisible.value) scrollToLastMessage({ smoothly: true })
}

const {
  liveChat,
  firstUnseenMessageId,
  setMessageVisibility,
  startPollingLiveChat,
  stopPollingLiveChat,
  unseenMessageNumber,
  pushLiveChatMessage,
} = useLiveChat({
  careFolderPublicId: data.value?.careFolder.publicId,
  claimMessages: data.value?.claims?.forEach((claim) => claim.messages) ?? [],
  onNewMessageReceived: handleNewMessageReceived,
})

const lastClaim = computed(() => {
  return data.value?.claims[data.value.claims.length - 1]
})

const isReadOnlyConversation = computed(() => {
  return lastClaim.value?.expired && isIntercomEnabled.value
})

function isLastClaim(index: number) {
  return data.value?.claims && index === data.value.claims.length - 1
}

const shouldDisplayLiveChat = computed(() => {
  if (isEmpty(data.value?.claims)) return false

  return (
    lastClaim.value?.resolutionOption === RESOLUTION_OPTION_TYPE_ENUM.liveChat
  )
})

async function sendMessage(values: { message: string; attachments: File[] }) {
  isMessageSending.value = true

  if (!data.value) return

  const attachmentsPromises = values.attachments.map((file) =>
    handleFileUpload(
      `${getHttpBaseUrl()}/services/uploads`,
      'care_asp_attachment',
      { careFolderId: data.value?.careFolder.customerRequestId },
      file,
    ),
  )

  const uploadResponses = await Promise.all(attachmentsPromises)
  const isUploadSuccessful = uploadResponses.every(
    (res) => res?.status === 'success',
  )

  if (!isUploadSuccessful) {
    throw new Error('Some attachments failed to upload')
  }

  try {
    const messageResponse = await $httpFetch(
      '/bm/customer_request/:careFolderId/customer/message',
      {
        pathParams: { careFolderId: data.value.careFolder.customerRequestId },
        body: {
          kind: 'CLIENT_TO_ALL',
          message: values.message,
          attachmentHandles: uploadResponses.map((res) => res?.handle),
        },
        method: 'POST',
      },
    )

    // optimistic update to display the last message although it is not fetched
    if (shouldDisplayLiveChat.value) {
      pushLiveChatMessage(messageResponse)
    } else {
      pushMessage(messageResponse)
      openSuccessToast()
    }

    await scrollToLastMessage({ smoothly: true })
    // Updating formRenderKey forces the re-rendering of the MessageForm,
    // resetting all fields. This is needed on Live Chat claims where the form
    // isn't destroyed after the submit call
    formRenderKey.value += 1
  } catch (err) {
    openErrorToast()

    const error = err as Error
    logger.error(error.message, {
      error,
      owners: ['bot-squad-care-platform-front'],
    })
  } finally {
    isMessageSending.value = false
  }
}

onMounted(() => {
  scrollToLastMessage()
  if (shouldDisplayLiveChat.value) {
    startPollingLiveChat()
  }
})

onUnmounted(() => {
  if (shouldDisplayLiveChat.value) {
    stopPollingLiveChat()
  }
})

// On client-side navigation we need to trigger the scrollToLastMessage after we fetch the messages.
watch(isConversationLoading, (isLoadingNewValue) => {
  if (!isLoadingNewValue && isFirstLoad.value) {
    scrollToLastMessage()
    isFirstLoad.value = false
  }
})

watch(
  conversationError,
  () => {
    if (conversationError.value?.statusCode === 404) {
      router.push({ name: ROUTE_NAMES.DASHBOARD.MY_ORDERS.SELF })
    }
  },
  {
    immediate: true,
  },
)
</script>
