<template>
  <ul class="space-y-4">
    <li
      v-for="customerIssue in props.customerIssues"
      :key="customerIssue.id"
      class="body-2"
    >
      {{ customerIssue.label }}
    </li>
  </ul>
</template>

<script lang="ts" setup>
import type { CustomerIssue } from '@backmarket/http-api/src/api-specs-claim-management/types/common'

const props = defineProps<{ customerIssues: CustomerIssue[] }>()
</script>
