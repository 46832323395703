<template>
  <div
    ref="messageRef"
    class="border-static-default-low rounded-lg space-y-8 border"
    :class="customClasses"
  >
    <span v-if="message.isInformative" class="caption">
      {{ i18n(translations.informativeMessage) }}
    </span>

    <div class="flex flex-row gap-12">
      <MessageAvatar
        class="shrink-0"
        :display-name="message.sender.displayName"
        :sender-type
      />

      <div class="grow space-y-8">
        <div class="flex h-40 flex-col justify-center gap-4">
          <div class="flex justify-between gap-12">
            <div class="body-1-bold">
              {{ message.sender.displayName }}
            </div>
            <ClientOnly>
              <div
                class="text-static-default-low body-1"
                data-test="message-time"
              >
                {{ messageTime }}
              </div>
            </ClientOnly>
          </div>
        </div>

        <MessageContent
          :content="message.content"
          :should-format-links="senderType === 'backmarket'"
        />

        <div v-if="message.attachments.length > 0">
          <div class="my-16 grid grid-cols-1 gap-16 lg:grid-cols-3">
            <Attachment
              v-for="attachment in message.attachments"
              :key="attachment.attachmentId"
              :attachment
              :care-folder-id
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, inject, onMounted, onUnmounted, ref } from 'vue'

import type { GetCareFolder } from '@backmarket/http-api/src/api-specs-claim-management/care-folder/types/care-folder'
import type { GetCareFolderBackcareAgentDiscussion } from '@backmarket/http-api/src/api-specs-claim-management/customer-request/types/claim'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { isIntersectionObserverAvailable } from '@backmarket/utils/env/isIntersectionObserverAvailable'

import { SCROLL_CONTAINER_INJECTION_KEY } from '../../symbols'

import Attachment from './Attachment/Attachment.vue'
import translations from './Message.translations'
import MessageAvatar from './MessageAvatar.vue'
import MessageContent from './MessageContent/MessageContent.vue'

const props = defineProps<{
  message: GetCareFolderBackcareAgentDiscussion.Message
  careFolderId: GetCareFolder.CareFolder['customerRequestId']
}>()

const emit = defineEmits(['intersect'])

const observer = ref<IntersectionObserver | null>(null)
const messageRef = ref<HTMLElement | null>(null)

const scrollContainerRef = inject(SCROLL_CONTAINER_INJECTION_KEY, null)

const i18n = useI18n()

const messageTime = computed(() =>
  i18n.date(new Date(props.message.creationDate), {
    timeStyle: 'short',
    dateStyle: 'short',
  }),
)

const senderType = computed(() => {
  const isAgent = props.message.kind.startsWith('BACKCARE_TO')
  const isStaff = props.message.kind.startsWith('ADMIN_TO')
  const isAutomatic = props.message.kind === 'AUTO'

  const isCustomer = props.message.kind.startsWith('CLIENT_TO')
  const isSeller = props.message.kind.startsWith('MERCHANT_TO')

  if (isAgent || isStaff || isAutomatic) return 'backmarket'
  if (isCustomer) return 'customer'
  if (isSeller) return 'seller'

  return null
})

const customClasses = computed(() => {
  if (['AUTO', 'ADMIN_TO_ALL'].includes(props.message.kind))
    return 'bg-surface-default-hi body-1-bold'

  return 'bg-surface-default-low'
})

function createIntersectionObserver() {
  if (!isIntersectionObserverAvailable()) return

  if (
    messageRef.value === null ||
    scrollContainerRef === null ||
    scrollContainerRef.value === null
  )
    return

  observer.value = new IntersectionObserver(
    (entries) => {
      const { isIntersecting } = entries[0]

      emit('intersect', {
        messageId: props.message.messageId,
        hasBeenSeen: isIntersecting,
      })

      if (isIntersecting && messageRef.value !== null) {
        observer.value?.unobserve(messageRef.value)
      }
    },
    {
      root: scrollContainerRef.value,
      threshold: 0.5,
    },
  )

  observer.value.observe(messageRef.value)
}

onMounted(() => {
  createIntersectionObserver()
})

onUnmounted(() => {
  if (observer.value) {
    observer.value.disconnect()
  }
})
</script>
