export function areDatesSameDay(date1: string, date2: string) {
  // Create Date objects
  const d1 = new Date(date1)
  const d2 = new Date(date2)

  // Set the time part of both Date objects to 00:00:00.000
  d1.setHours(0, 0, 0, 0)
  d2.setHours(0, 0, 0, 0)

  // Compare the two Date objects
  return d1.getTime() === d2.getTime()
}
