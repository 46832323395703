<template>
  <RevDocument
    :error-message
    :name="name || undefined"
    :status="status as DocumentStatus"
    :url="url || undefined"
  />
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from 'vue'

import { poll } from '@backmarket/front-upload'
import { getCareFolder } from '@backmarket/http-api/src/api-specs-claim-management/care-folder/care-folder'
import type { GetCareFolder } from '@backmarket/http-api/src/api-specs-claim-management/care-folder/types/care-folder'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { getHttpBaseUrl } from '@backmarket/nuxt-module-http/getHttpBaseUrl'
import { RevDocument } from '@ds/components/Document'
import type { DocumentStatus } from '@ds/components/Document/Document.constant'

const apiBaseUrl = getHttpBaseUrl()

const props = defineProps<{
  attachment: GetCareFolder.CareFolder['attachments'][number]
  careFolderId: GetCareFolder.CareFolder['customerRequestId']
}>()

const name = ref(props.attachment.name)
const url = ref(props.attachment.url)
const errorMessage = ref<string | undefined>()
const cancelPoll = ref<() => void>()

const isLoading = computed(() => {
  const hasAttachment = !!props.attachment.handle
  const isLoaded = name.value || url.value

  return hasAttachment && !isLoaded
})

const status = computed(() => {
  if (errorMessage.value) return 'error'
  if (isLoading.value) return 'loading'

  return 'default'
})

async function getAttachment(givenId: number) {
  /* TODO: AXE-638 This call should be replaced with a call to the attachment itself as
           soon as GET attachment endpoint is ready */
  const careFolder = await $httpFetch(getCareFolder, {
    pathParams: {
      careFolderId: props.careFolderId.toString(),
    },
  })

  return careFolder.attachments.find(
    ({ attachmentId }) => attachmentId === givenId,
  )
}

// As the uploading of the attachement is done asynchronously, we need to poll the attachment status until it is success
// Once the attachment is ready, we need to fetch the care folder to get the attachement url
onMounted(async () => {
  if (isLoading.value && props.attachment.handle) {
    cancelPoll.value = await poll(
      `${apiBaseUrl}/services/uploads`,
      props.attachment.handle,
      {
        onSuccess: async () => {
          try {
            const attachment = await getAttachment(
              props.attachment.attachmentId,
            )

            if (attachment) {
              name.value = attachment.name
              url.value = attachment.url
            }
          } catch (error) {
            errorMessage.value = 'File error'
          }
        },
        onFailure: async (payload) => {
          errorMessage.value = payload.statusReason
        },
        onError: async (error) => {
          errorMessage.value = error
        },
      },
    )
  }
})

onUnmounted(() => {
  if (cancelPoll.value) {
    cancelPoll.value()
  }
})
</script>
