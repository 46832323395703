export default {
  inputLabel: {
    id: 'asp_live_chat_send_message_form_label',
    defaultMessage: 'Your message',
  },
  requiredMessageError: {
    id: 'asp_live_chat_send_message_form_required_error',
    defaultMessage: 'This field is required',
  },
}
