<template>
  <DragProvider @drop="handleDrop">
    <template #default="{ isDraggingInPage, isDraggingInArea }">
      <div
        class="absolute inset-0"
        :class="{
          'pointer-events-none': !isDraggingInPage,
        }"
      >
        <Overlay v-if="isDraggingInPage" :is-opaque="isDraggingInArea" />
      </div>
    </template>
  </DragProvider>

  <slot :add-attachments />

  <input
    ref="attachments"
    :accept="ACCEPTED_FILE_EXTENSIONS"
    :aria-label="i18n(translations.addAttachment)"
    class="hidden"
    multiple
    name="attachments"
    type="file"
    @change="
      (event) =>
        handleAttachmentsUpload((event.target as HTMLInputElement).files)
    "
  />
  <div
    v-if="modelValue.length"
    class="my-16 grid grid-cols-1 gap-4 lg:grid-cols-3"
  >
    <RevDocument
      v-for="attachment in modelValue"
      :key="getAttachmentIdentifier(attachment.name, attachment.size)"
      class="my-4 md:mr-6"
      deletable
      :name="attachment.name"
      @delete="removeAttachment(attachment)"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTheToast } from '@backmarket/nuxt-module-toast/useTheToast'
import { humanReadableSize } from '@backmarket/utils/math/humanReadableSize'
import { RevDocument } from '@ds/components/Document'

import {
  ACCEPTED_FILE_EXTENSIONS,
  MAX_ATTACHMENTS_SIZE,
} from './AttachmentsUpload.constants'
import translations from './AttachmentsUpload.translations'
import DragProvider from './DragAndDrop/DragProvider.vue'
import Overlay from './Overlay.vue'

const i18n = useI18n()
const props = defineProps<{ modelValue: File[] }>()
const { openErrorToast } = useTheToast()

const emit = defineEmits(['update:modelValue'])

const attachments = ref<HTMLInputElement | null>(null)

const attachmentsSize = computed(() => {
  return props.modelValue.reduce((totalSize, file) => totalSize + file.size, 0)
})

function getAttachmentIdentifier(name: string, size: number) {
  return `${name}-${size}`
}

async function resetInput() {
  if (attachments.value) {
    attachments.value.value = ''
  }
}

async function updateValue(value: File[]) {
  emit('update:modelValue', value)
  // Because file input keep in its own state the path of the first selected file,
  // we should reset it in order to be able to add or remove files
  resetInput()
}

async function handleAttachmentsUpload(files: FileList | null) {
  if (!files) {
    return
  }

  const attachmentsToAdd = Array.from(files).filter(
    (file) => attachmentsSize.value + file.size <= MAX_ATTACHMENTS_SIZE,
  )

  const hasRejectedAttachments = files.length !== attachmentsToAdd.length
  if (hasRejectedAttachments) {
    openErrorToast({
      content: i18n(translations.tooBigAttachments, {
        maxSize: humanReadableSize(
          MAX_ATTACHMENTS_SIZE,
          i18n(translations.unit),
          1000,
        ),
      }),
    })
  }

  const updatedAttachments = [...props.modelValue, ...attachmentsToAdd]
  updateValue(updatedAttachments)
}

function handleDrop({ dataTransfer }: { dataTransfer: DataTransfer }) {
  handleAttachmentsUpload(dataTransfer?.files)
}

function addAttachments() {
  if (attachments.value) {
    attachments.value.click()
  }
}

async function removeAttachment(attachmentToRemove: File) {
  const updatedAttachments = props.modelValue.filter(
    (attachment) =>
      getAttachmentIdentifier(attachment.name, attachment.size) !==
      getAttachmentIdentifier(attachmentToRemove.name, attachmentToRemove.size),
  )

  updateValue(updatedAttachments)
}
</script>
