export default {
  labelReturn: {
    id: 'sav_discussion_separator_auto_prepaid_label',
    defaultMessage: 'Return label already sent.',
  },
  used: {
    id: 'sav_discussion_used_tag',
    defaultMessage: 'Used',
  },
  available: {
    id: 'sav_discussion_available_tag',
    defaultMessage: 'Available',
  },
  expired: {
    id: 'sav_discussion_expired_tag',
    defaultMessage: 'Expired',
  },
  qrCodeLabel: {
    id: 'sav_discussion_separator_auto_qr_code_label',
    defaultMessage: 'QR code already sent',
  },
}
