<template>
  <DragPageProvider>
    <template #default="{ isDraggingInPage }">
      <DragAreaProvider
        :class="$attrs.class"
        @drop="(event) => emit('drop', event)"
      >
        <template #default="{ isDraggingInArea }">
          <slot :is-dragging-in-area :is-dragging-in-page />
        </template>
      </DragAreaProvider>
    </template>
  </DragPageProvider>
</template>

<script setup lang="ts">
import DragAreaProvider from './DragAreaProvider.vue'
import DragPageProvider from './DragPageProvider.vue'

const emit = defineEmits(['drop'])
</script>
