<template>
  <RevInfoBlock
    :caption="warrantyStateTranslation"
    class="bg-static-warning-low"
    :icon="IconWarning"
    :title="i18n(translations.title)"
    variant="warning"
  >
    <CustomerIssues v-if="customerIssues.length" :customer-issues />

    <LabelReturn v-if="labelReturn" :label-return />
  </RevInfoBlock>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import type { GetCareFolderBackcareAgentDiscussion } from '@backmarket/http-api/src/api-specs-claim-management/customer-request/types/claim'
import type { CustomerIssue } from '@backmarket/http-api/src/api-specs-claim-management/types/common'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevInfoBlock } from '@ds/components/InfoBlock'
import { IconWarning } from '@ds/icons/IconWarning'

import CustomerIssues from '../CustomerIssues/CustomerIssues.vue'
import LabelReturn from '../LabelReturn/LabelReturn.vue'

import translations from './OutOfWarrantyInfoBlock.translations'

type DeclaredWarrantyState =
  GetCareFolderBackcareAgentDiscussion.DeclaredWarrantyState
type LabelReturnType = GetCareFolderBackcareAgentDiscussion.LabelReturn

const i18n = useI18n()

const props = defineProps<{
  customerIssues: CustomerIssue[]
  warrantyState?: DeclaredWarrantyState | null
  labelReturn: LabelReturnType | null
}>()

const warrantyStateTranslation = computed(() => {
  switch (props.warrantyState) {
    case 'WATER_OR_DROPPED':
      return i18n(translations.waterOrDropped)
    case 'THIRD_PARTY_REPAIR':
      return i18n(translations.thirdPartyRepair)
    default:
      return ''
  }
})
</script>
