export default {
  notificationsDisabled: {
    id: 'sav_conversation_notification_disabled',
    defaultMessage: 'Email notifications disabled',
  },
  notificationsEnabled: {
    id: 'sav_conversation_notification_enabled',
    defaultMessage: 'Email notifications enabled',
  },
  notificationToggleError: {
    id: 'sav_conversation_notification_toggle_error',
    defaultMessage: 'An error occurred',
  },
}
