<template>
  <slot :is-dragging-in-page />
</template>

<script setup lang="ts">
import { onBeforeMount, onUnmounted, ref } from 'vue'

const counter = ref(0)
const isDraggingInPage = ref(false)

function handleDragEnter() {
  isDraggingInPage.value = true
  counter.value += 1
}
function handleDragLeave() {
  counter.value -= 1
  if (counter.value === 0) {
    isDraggingInPage.value = false
  }
}

onBeforeMount(() => {
  document.addEventListener('dragenter', handleDragEnter)
  document.addEventListener('dragleave', handleDragLeave)
  document.addEventListener('drop', handleDragLeave)
})

onUnmounted(() => {
  document.removeEventListener('dragenter', handleDragEnter)
  document.removeEventListener('dragleave', handleDragLeave)
  document.removeEventListener('drop', handleDragLeave)
})
</script>
