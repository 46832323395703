<template>
  <RevInfoBlock
    :cta="i18n(translations.tryAgain)"
    :icon="IconWarning"
    :title="i18n(translations.errorTitle)"
    variant="danger"
    @click="emit('try-again')"
  >
    <div class="body-2">
      {{ i18n(translations.errorContent) }}
      <RevLink underlined @click="showDetails">
        {{ i18n(translations.viewMore) }}
      </RevLink>

      <div v-if="shouldDisplayDetails" class="py-16">
        <div v-if="errorMessage">{{ errorMessage }}</div>
      </div>
    </div>
  </RevInfoBlock>
</template>

<script setup lang="ts">
import { ref } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevInfoBlock } from '@ds/components/InfoBlock'
import { RevLink } from '@ds/components/Link'
import { IconWarning } from '@ds/icons/IconWarning'

import translations from './ErrorInfoBlock.translations'

const i18n = useI18n()

defineProps<{
  errorMessage?: string
}>()

const emit = defineEmits(['try-again'])

const shouldDisplayDetails = ref(false)

function showDetails() {
  shouldDisplayDetails.value = true
}
</script>
