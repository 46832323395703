export default {
  title: {
    id: 'sav_platform_page_title',
    defaultMessage: 'Customer care | Back Market',
  },
  newMessages: {
    id: 'asp_conversation_details_agent_live_chat_new_messages',
    defaultMessage: 'New message(s) ({number})',
  },
}
