import { useRuntimeConfig } from '#imports'
import { type Ref, computed, nextTick, ref } from 'vue'

import type { GetLiveChat } from '@backmarket/http-api/src/api-specs-claim-management/care-folder/types/live-chat'
import type { GetCareFolderBackcareAgentDiscussion } from '@backmarket/http-api/src/api-specs-claim-management/customer-request/types/claim'
import { useHttpFetch } from '@backmarket/nuxt-module-http-v2/useHttpFetch'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { isEmpty } from '@backmarket/utils/object/isEmpty'

export function useLiveChat({
  careFolderPublicId,
  claimMessages,
  onNewMessageReceived,
}: {
  careFolderPublicId?: string
  claimMessages: GetCareFolderBackcareAgentDiscussion.Message[]
  onNewMessageReceived?: () => void
}) {
  const {
    FF_LIVE_CHAT_POLLING_INT_MS_TERMINATED,
    FF_LIVE_CHAT_POLLING_INT_MS,
  } = useRuntimeConfig().public

  const logger = useLogger()
  const $httpFetch = useHttpFetch()

  const liveChat = ref<GetLiveChat.Response>({
    messages: [],
    status: 'PENDING',
  })

  const messageVisibilityDictionnary: Ref<Record<number, boolean>> = ref(
    claimMessages.reduce(
      (messageDict, claimMessage) => ({
        ...messageDict,
        [claimMessage.messageId]: true,
      }),
      {},
    ),
  )

  const unseenMessageIds = computed(() => {
    const messageVisibility = Object.entries(messageVisibilityDictionnary.value)

    return messageVisibility
      .filter(([_, hasBeenSeen]) => hasBeenSeen === false)
      .map(([messageId]) => messageId)
  })

  const firstUnseenMessageId = computed(() => {
    return unseenMessageIds.value[0]
  })

  const unseenMessageNumber = computed(() => {
    return unseenMessageIds.value.length
  })

  let pollingIntervalId: NodeJS.Timeout | null

  const pollingInterval = computed(() => {
    return liveChat.value?.status === 'TERMINATED'
      ? FF_LIVE_CHAT_POLLING_INT_MS_TERMINATED
      : FF_LIVE_CHAT_POLLING_INT_MS
  })

  function setMessageVisibility({
    messageId,
    hasBeenSeen,
  }: {
    messageId: number
    hasBeenSeen: boolean
  }) {
    if (messageVisibilityDictionnary.value[messageId]) return

    messageVisibilityDictionnary.value[messageId] = hasBeenSeen
  }

  async function fetchLiveChat() {
    if (!careFolderPublicId) return
    const response = await $httpFetch(
      '/bm/care-folders/:careFolderPublicId/live-chat',
      {
        pathParams: { careFolderPublicId },
      },
    )

    if (
      isEmpty(response) ||
      isEmpty(response.status) ||
      isEmpty(response.messages)
    ) {
      return
    }
    const previousMessagesNumber = liveChat.value.messages.length
    const newMessagesNumber = response.messages.length
    const hasNewMessage = newMessagesNumber > previousMessagesNumber

    liveChat.value.messages = response.messages
    liveChat.value.status = response.status

    if (!hasNewMessage) return

    if (onNewMessageReceived) {
      /* Wait for the end of the re-rendering cycle to trigger the callback,
      ensuring new messages have been added to the DOM */
      await nextTick()
      onNewMessageReceived()
    }

    if (!hasNewMessage) return

    if (onNewMessageReceived) {
      /* Wait for the end of the re-rendering cycle to trigger the callback,
      ensuring new messages have been added to the DOM */
      await nextTick()
      onNewMessageReceived()
    }

    if (document.hidden) {
      const previousMessageIds = Object.keys(messageVisibilityDictionnary)

      const newMessages = liveChat.value.messages.filter(
        ({ messageId }) => !previousMessageIds.includes(messageId.toString()),
      )

      newMessages.forEach(({ messageId }) =>
        setMessageVisibility({
          messageId,
          hasBeenSeen: false,
        }),
      )
    }
  }

  async function startPollingLiveChat() {
    try {
      await fetchLiveChat()
    } catch (err) {
      const error = err as Error
      logger.error(error.message, {
        error,
        owners: ['bot-squad-care-platform-front'],
      })
    }

    if (pollingIntervalId !== null) {
      pollingIntervalId = setTimeout(
        startPollingLiveChat,
        pollingInterval.value,
      )
    }

    // Return a promise in function argument to force the type of the callback
    return Promise.resolve()
  }

  function pushLiveChatMessage(message: GetLiveChat.Message) {
    liveChat.value.messages.push(message)
  }

  function stopPollingLiveChat() {
    if (pollingIntervalId !== null) {
      clearTimeout(pollingIntervalId)
      pollingIntervalId = null
    }
  }

  return {
    setMessageVisibility,
    startPollingLiveChat,
    stopPollingLiveChat,
    pushLiveChatMessage,
    liveChat,
    firstUnseenMessageId,
    unseenMessageNumber,
  }
}
