<template>
  <RevInfoBlock class="bg-static-info-low" :icon :title variant="info">
    <RevTag
      v-if="backRepairTagLabel"
      :label="backRepairTagLabel"
      variant="info"
    />

    <CustomerIssues
      v-if="claim.customerIssues.length"
      :customer-issues="claim.customerIssues"
    />

    <LabelReturn v-if="claim.labelReturn" :label-return="claim.labelReturn" />
  </RevInfoBlock>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import type { GetCareFolderBackcareAgentDiscussion } from '@backmarket/http-api/src/api-specs-claim-management/customer-request/types/claim'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevInfoBlock } from '@ds/components/InfoBlock'
import { RevTag } from '@ds/components/Tag'
import { IconExport } from '@ds/icons/IconExport'
import { IconHeartDiagnostic } from '@ds/icons/IconHeartDiagnostic'
import { IconRefund } from '@ds/icons/IconRefund'
import { IconTools } from '@ds/icons/IconTools'

import CustomerIssues from '../CustomerIssues/CustomerIssues.vue'
import LabelReturn from '../LabelReturn/LabelReturn.vue'

import translations from './InWarrantyInfoBlock.translations'

type Claim = GetCareFolderBackcareAgentDiscussion.Claim

const i18n = useI18n()

const props = defineProps<{ claim: Claim }>()

const icon = computed(() => {
  switch (props.claim.resolutionType) {
    case 'FACTORY_REPAIR':
    case 'SHOP_REPAIR':
    case 'REPAIR_REPLACE':
    case 'SELLER_REPAIR':
      return IconTools

    case 'REMOTE_ASSISTANCE':
      return IconHeartDiagnostic

    case 'REFUND':
      return IconRefund

    case 'SELLER_REPLACE':
    case 'BACKREPLACE':
      return IconExport

    default:
      break
  }

  switch (props.claim.resolutionOption) {
    case 'LIVE_CHAT':
    case 'REMOTE_ASSISTANCE':
      return IconHeartDiagnostic

    case 'REPAIR_REPLACE':
    case 'REPAIR':
      return IconTools

    case 'REFUND':
      return IconRefund

    case 'REPLACE':
      return IconExport

    default:
      return IconHeartDiagnostic
  }
})

const title = computed(() =>
  i18n(translations.title, {
    resolution: props.claim.resolutionType || props.claim.resolutionOption,
    count: props.claim.customerIssues.length,
    source: props.claim.source,
  }),
)

const backRepairTagLabel = computed(() => {
  if (props.claim.resolutionType === 'FACTORY_REPAIR') {
    return `${i18n(translations.backRepair)} • ${i18n(translations.mailIn)}`
  }

  if (props.claim.resolutionType === 'SHOP_REPAIR') {
    return `${i18n(translations.backRepair)} • ${i18n(translations.inShop)}`
  }

  return null
})
</script>
