<!-- eslint-disable vuejs-accessibility/no-static-element-interactions -->
<template>
  <div
    @dragenter="handleDragEnter"
    @dragleave="handleDragLeave"
    @drop="handleDrop"
  >
    <slot :is-dragging-in-area />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'

const emit = defineEmits(['drop'])

const counter = ref(0)
const isDraggingInArea = ref(false)

function handleDragEnter() {
  isDraggingInArea.value = true
  counter.value += 1
}

function handleDragLeave() {
  counter.value -= 1
  if (counter.value === 0) {
    isDraggingInArea.value = false
  }
}

function handleDrop(event: Event) {
  counter.value -= 1
  isDraggingInArea.value = false
  emit('drop', event)
}
</script>
