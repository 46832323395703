<template>
  <RevButtonIcon
    v-if="!areNotificationEnabledPending"
    :alternative-text="notificationStatusTranslation"
    :disabled="isToggling"
    :icon
    :loading="isToggling"
    variant="secondary"
    @click="toggle"
  />
</template>

<script lang="ts" setup>
import { useAsyncData } from '#imports'
import { computed, ref } from 'vue'

import type { GetCareFolder } from '@backmarket/http-api/src/api-specs-claim-management/care-folder/types/care-folder'
import { useHttpFetch } from '@backmarket/nuxt-module-http-v2/useHttpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { useTheToast } from '@backmarket/nuxt-module-toast/useTheToast'
import { RevButtonIcon } from '@ds/components/ButtonIcon'
import { IconBellDisabled } from '@ds/icons/IconBellDisabled'
import { IconBellOutlined } from '@ds/icons/IconBellOutlined'

import translations from './Notification.translations'

const logger = useLogger()
const i18n = useI18n()
const { openErrorToast, openSuccessToast } = useTheToast()
const $httpFetch = useHttpFetch()

const props = defineProps<{
  careFolderId: GetCareFolder.CareFolder['customerRequestId']
}>()

const { data: areNotificationEnabled, pending: areNotificationEnabledPending } =
  useAsyncData(
    () =>
      $httpFetch('/bm/customer_request/:careFolderId/customer_notification', {
        pathParams: {
          careFolderId: props.careFolderId,
        },
      }),
    {
      default: () => false,
      transform: (response) => response.customerNotification,
    },
  )

const isToggling = ref(false)

const icon = computed(() =>
  areNotificationEnabled.value ? IconBellOutlined : IconBellDisabled,
)

const notificationStatusTranslation = computed(() =>
  areNotificationEnabled.value
    ? i18n(translations.notificationsEnabled)
    : i18n(translations.notificationsDisabled),
)

async function toggle() {
  isToggling.value = true

  try {
    await $httpFetch(
      '/bm/customer_request/:careFolderId/customer_notification',
      {
        pathParams: { careFolderId: props.careFolderId },
        body: {
          customerNotification: areNotificationEnabled.value,
        },
        method: 'PUT',
      },
    )

    areNotificationEnabled.value = !areNotificationEnabled.value

    openSuccessToast({
      content: notificationStatusTranslation.value,
    })
  } catch (err) {
    const error = err as Error
    logger.error('An error occurred while toggling the notifications', {
      error,
      owners: ['bot-squad-care-platform-front'],
    })

    openErrorToast({
      content: i18n(translations.notificationToggleError),
    })
  } finally {
    isToggling.value = false
  }
}
</script>
