<template>
  <div class="body-2-bold flex grow flex-col items-center gap-1 text-center">
    <div>{{ i18n(title) }}</div>

    <div v-if="status" class="flex flex-col">
      <span v-if="status === 'ACTIVE'" class="flex items-center">
        <span
          class="bg-static-success-hi rounded-full mr-8 inline-block h-12 w-12 animate-pulse"
        />

        {{ i18n(translations.activeStatus) }}
      </span>

      <span v-if="status === 'PENDING'" class="flex items-center">
        <span
          class="bg-static-info-hi rounded-full mr-8 inline-block h-12 w-12"
        />
        {{ i18n(translations.pendingStatus) }}
      </span>

      <span v-if="status === 'TERMINATED'" class="flex items-center">
        <span
          class="bg-static-danger-hi rounded-full mr-8 inline-block h-12 w-12"
        />
        {{ i18n(translations.terminatedStatus) }}
      </span>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import translations from './LiveChatStatus.translations'

const i18n = useI18n()

const props = defineProps({
  status: {
    type: String,
    default: null,
  },
  customerRequestId: {
    type: Number,
    required: true,
  },
})

const title = computed(() =>
  props.status === 'TERMINATED'
    ? translations.terminatedTitle
    : translations.regularTitle,
)
</script>
