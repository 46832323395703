<template>
  <RevBanner
    v-if="shouldDisplayExtraDelayBanner"
    close-alternative-text="Close banner"
    :label="i18n(translations.extraDelayBannerLabel)"
    @close="closeInformativeBannerDisplayed"
  />
</template>

<script lang="ts" setup>
import { storeToRefs, useRuntimeConfig } from '#imports'
import { computed, onMounted } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { RevBanner } from '@ds/components/Banner'

import { useCustomerConversationStore } from '~/scopes/customer-conversation/store/useCustomerConversationStore'

import translations from './Discussion.translations'

const i18n = useI18n()
const { market } = useMarketplace()

const { closeInformativeBannerDisplayed, displayInformativeBanner } =
  useCustomerConversationStore()
const { isInformativeBannerDisplayed } = storeToRefs(
  useCustomerConversationStore(),
)

const { FF_BANNER_REQUEST_TREATMENT_DELAY_INFO_BY_COUNTRIES } =
  useRuntimeConfig().public

const shouldDisplayExtraDelayBanner = computed(
  () =>
    (FF_BANNER_REQUEST_TREATMENT_DELAY_INFO_BY_COUNTRIES as string)?.includes(
      market.countryCode,
    ) && isInformativeBannerDisplayed.value,
)

onMounted(() => {
  displayInformativeBanner()
})
</script>
