export default {
  addAttachment: {
    id: 'asp_action_bar_attachments_upload_add_attachment',
    defaultMessage: 'Add an attachment',
  },
  tooBigAttachments: {
    id: 'asp_action_bar_attachments_upload_error_too_big_attachments',
    defaultMessage: 'Oops, the maximum files size should not exceed {maxSize}',
  },
  inputLabel: {
    id: 'asp_action_bar_attachments_upload_input_label',
    defaultMessage: 'Add an attachment',
  },
  unit: {
    id: 'asp_action_bar_attachments_upload_byte_unit',
    defaultMessage: 'B',
  },
  dropzoneHint: {
    id: 'asp_action_bar_attachments_upload_dropzone_hint',
    defaultMessage: '"Drop it like it\'s hot!" - Snoop Dogg',
  },
}
