<template>
  <div
    v-for="(message, index) in messages"
    :key="message.messageId"
    class="flex w-full flex-col items-center space-y-16"
    data-qa="claim-message"
  >
    <Timestamp
      v-if="isMessageNewDay(message, index)"
      :date="message.creationDate"
    />

    <Message
      :care-folder-id
      class="w-full p-16 last:pb-12 lg:max-w-[57rem]"
      :message
    />
  </div>
</template>

<script setup lang="ts">
import type { GetCareFolder } from '@backmarket/http-api/src/api-specs-claim-management/care-folder/types/care-folder'
import type { GetCareFolderBackcareAgentDiscussion } from '@backmarket/http-api/src/api-specs-claim-management/customer-request/types/claim'

import { areDatesSameDay } from '../../Conversation.utils'
import Message from '../Message/Message.vue'
import Timestamp from '../Timestamp/Timestamp.vue'

const props = defineProps<{
  messages: GetCareFolderBackcareAgentDiscussion.Message[]
  careFolderId: GetCareFolder.CareFolder['customerRequestId']
}>()

function isMessageNewDay(
  currentMessage: GetCareFolderBackcareAgentDiscussion.Message,
  index: number,
) {
  if (index === 0) return false

  const previousMessage = props.messages[index - 1]

  return !areDatesSameDay(
    currentMessage.creationDate,
    previousMessage.creationDate,
  )
}
</script>
