<template>
  <RevForm
    autocomplete="off"
    data-qa="message-form"
    :initial-values
    :name
    :validate="validate()"
    @submit="(values) => emit('submit', values)"
  >
    <template #default="{ values }">
      <div class="space-y-20">
        <AttachmentsUpload v-model="values.attachments">
          <template #default="{ addAttachments }">
            <div class="flex w-full gap-8">
              <div class="flex grow items-center">
                <div class="flex-1">
                  <RevInputTextarea
                    v-if="!isLoading"
                    id="message"
                    v-model="values.message"
                    data-qa="input-text-area"
                    :label="i18n(translations.inputLabel)"
                    :max-rows="maxRowsByDevice"
                    name="message"
                  />
                </div>
                <RevSkeleton
                  v-if="isLoading"
                  height="48px"
                  shape="rectangle"
                  width="100%"
                />

                <RevButtonIcon
                  class="ml-12"
                  :icon="IconAttachment"
                  variant="secondary"
                  @click="addAttachments"
                />
              </div>
            </div>
          </template>
        </AttachmentsUpload>
      </div>
      <RevButton
        class="float-end mt-12 shrink-0"
        data-qa="submit-button"
        :disabled="isLoading || isEmpty(values.message.trim())"
        :loading="isLoading"
        type="submit"
        variant="primary"
      >
        {{ submitLabel }}
      </RevButton>
    </template>
  </RevForm>
</template>

<script setup lang="ts">
import { computed, reactive } from 'vue'

import type { GetCareFolder } from '@backmarket/http-api/src/api-specs-claim-management/care-folder/types/care-folder'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useDangerouslyComputedBreakpoint } from '@backmarket/utils/composables/useDangerouslyComputedBreakpoint'
import { Breakpoint } from '@backmarket/utils/dom/getCurrentBreakpoint'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevButton } from '@ds/components/Button'
import { RevButtonIcon } from '@ds/components/ButtonIcon'
import { RevForm, makeValidate, required } from '@ds/components/Form'
import { RevInputTextarea } from '@ds/components/InputTextarea'
import { RevSkeleton } from '@ds/components/Skeleton'
import { IconAttachment } from '@ds/icons/IconAttachment'

import AttachmentsUpload from '../AttachmentsUpload/AttachmentsUpload.vue'

import translations from './MessageForm.translations'

const i18n = useI18n()
const breakpoint = useDangerouslyComputedBreakpoint()

defineProps<{
  submitLabel: string
  isLoading: boolean
  careFolder: GetCareFolder.CareFolder
  zone: string
  name: string
}>()

const emit = defineEmits(['submit'])

type FormValues = {
  message: ''
  attachments: File[]
}

const initialValues = reactive<FormValues>({
  message: '',
  attachments: [],
})

function validate() {
  return makeValidate<FormValues>({
    message: [required(i18n(translations.requiredMessageError))],
  })
}

const maxRowsByDevice = computed(() =>
  breakpoint.value < Breakpoint.MD ? 4 : 10,
)
</script>
