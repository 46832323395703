<template>
  <div class="flex w-full justify-between px-16 py-12">
    <RevButtonIcon
      :alternative-text="i18n(translations.backButton)"
      :icon="IconArrowLeft"
      variant="secondary"
      @click="navigateToMyOrders"
    />
    <LiveChatStatus
      v-if="isLiveChatActive"
      :customer-request-id="careFolderId"
      :status
    />
    <Notification v-if="!pending && careFolderId" :care-folder-id />
  </div>
</template>

<script setup lang="ts">
import { navigateTo } from '#imports'

import type { GetCareFolder } from '@backmarket/http-api/src/api-specs-claim-management/care-folder/types/care-folder'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButtonIcon } from '@ds/components/ButtonIcon'
import { IconArrowLeft } from '@ds/icons/IconArrowLeft'

import { ROUTE_NAMES } from '@/scopes/dashboard/constants'

import LiveChatStatus from '../LiveChatStatus/LiveChatStatus.vue'
import Notification from '../Notification/Notification.vue'

import translations from './Header.translations'

const i18n = useI18n()

defineProps<{
  pending: boolean
  careFolderId?: GetCareFolder.CareFolder['customerRequestId']
  status?: string
  isLiveChatActive: boolean
}>()

function navigateToMyOrders() {
  navigateTo({
    name: ROUTE_NAMES.DASHBOARD.MY_ORDERS.SELF,
  })
}
</script>
