export default {
  errorTitle: {
    id: 'care_folder_error_title',
    defaultMessage: 'An error occurred',
  },
  errorContent: {
    id: 'care_folder_error_content',
    defaultMessage:
      'Our server is a bit slow at the moment. Please give it a sec and try again.',
  },
  viewMore: {
    id: 'care_folder_error_view_more_link',
    defaultMessage: 'View more',
  },
  tryAgain: {
    id: 'care_folder_error_try_again_action',
    defaultMessage: 'Try again',
  },
}
