export default {
  regularTitle: {
    id: 'asp_live_chat_title',
    defaultMessage: 'You’re in a live chat.',
  },
  terminatedTitle: {
    id: 'asp_live_chat_title_terminated',
    defaultMessage:
      'Chat ended. The agent will reply within 1 day if you message them.',
  },
  activeStatus: {
    id: 'asp_live_chat_status_active_status',
    defaultMessage: 'The agent is working on your issue',
  },
  pendingStatus: {
    id: 'asp_live_chat_status_pending_status',
    defaultMessage: 'Searching for an available agent',
  },
  terminatedStatus: {
    id: 'asp_live_chat_status_terminated_status',
    defaultMessage: 'Agent unavailable',
  },
  backButton: {
    id: 'asp_live_chat_back_button',
    defaultMessage: 'Back',
  },
}
