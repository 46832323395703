export default {
  title: {
    id: 'sav_discussion_separator_out_of_warranty',
    defaultMessage: 'Help request - Out of warranty',
  },
  waterOrDropped: {
    id: 'dashboard_diagnosis_out_of_warranty_damage',
    defaultMessage:
      'It was exposed to water/humidity or dropped/broken by me or someone else.',
  },
  thirdPartyRepair: {
    id: 'dashboard_diagnosis_out_of_warranty_unauthorized_repair',
    defaultMessage: 'Someone other than the refurbisher tried to repair it.',
  },
}
