export default {
  visibleByCustomer: {
    id: 'sav_discussion_message_client_only',
    defaultMessage: 'Message visible by the customer only',
  },
  visibleBySeller: {
    id: 'sav_discussion_message_merchant_only',
    defaultMessage: 'Message visible by the seller only',
  },
  visibleByYou: {
    id: 'sav_discussion_message_you_only',
    defaultMessage: 'Message visible by you only',
  },
  informativeMessage: {
    id: 'sav_platform_reply_not_expected_tag',
    defaultMessage: 'No reply necessary. The below is just FYI',
  },
  submit: {
    id: 'asp_action_bar_send_message_with_template_selector_submit_button',
    defaultMessage: 'Send message',
  },
}
