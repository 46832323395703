<template>
  <div class="flex items-center gap-8">
    <RevLink
      class="body-2-link"
      target="_blank"
      :to="props.labelReturn.shipmentLabelUrl"
    >
      {{ i18n(translations.labelReturn) }}
    </RevLink>

    <RevLink
      v-if="props.labelReturn.qrCodeUrl"
      class="body-2-link"
      target="_blank"
      :to="props.labelReturn.qrCodeUrl"
    >
      {{ i18n(translations.qrCodeLabel) }}
    </RevLink>

    <RevTag
      v-if="returnStateTagConfig.label"
      :label="returnStateTagConfig.label"
      :variant="returnStateTagConfig.variant"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import type { GetCareFolderBackcareAgentDiscussion } from '@backmarket/http-api/src/api-specs-claim-management/customer-request/types/claim'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevLink } from '@ds/components/Link'
import { RevTag } from '@ds/components/Tag'

import translations from './LabelReturn.translations'

const i18n = useI18n()

type LabelReturn = GetCareFolderBackcareAgentDiscussion.LabelReturn

const props = defineProps<{ labelReturn: LabelReturn }>()

const returnStateTagConfig = computed(() => {
  switch (props.labelReturn.state) {
    case 'PRODUCT_RETURN_DELIVERED':
    case 'RETURN_OF_PRODUCT_RETURN_PICKED_UP':
      return { label: i18n(translations.used), variant: 'success' as const }
    case 'RETURN_OF_PRODUCT_RETURN_CREATED':
      return {
        label: i18n(translations.available),
        variant: 'primary' as const,
      }
    case 'PRODUCT_RETURN_EXPIRED':
      return { label: i18n(translations.expired), variant: 'danger' as const }
    default:
      return { label: '', variant: 'danger' as const }
  }
})
</script>
