import { useAsyncData, useNuxtApp, useRouteParams } from '#imports'

import type { ApiSpecs } from '@backmarket/nuxt-module-http-v2/specs'
import { useHttpFetch } from '@backmarket/nuxt-module-http-v2/useHttpFetch'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'

type CustomerConversationMessage =
  ApiSpecs['/bm/customer_request/:careFolderId/customer/message']['POST']['response']

export function useCustomerConversation() {
  const nuxtApp = useNuxtApp()
  const logger = useLogger()
  const $httpFetch = useHttpFetch()

  const { careFolderId, resolutionId } = useRouteParams()

  const { data, pending, error, refresh } = useAsyncData(async () => {
    const endpointToGetCareFolder = careFolderId
      ? $httpFetch('/bm/customer_request/:careFolderId/information', {
          pathParams: {
            careFolderId,
          },
        })
      : $httpFetch(
          '/bm/customer_request/resolutions/:resolutionId/information',
          {
            pathParams: {
              resolutionId,
            },
          },
        )

    const careFolder = await endpointToGetCareFolder

    /**
     * Need to use nuxtApp.runWithContext to preserve context when using methods that need access to the nuxt instance ($httpFetch here).
     * TODO: Remove this as the feature will be supported soon by nuxt (see asyncContext)
     */
    const claims = await nuxtApp.runWithContext(async () => {
      if (!careFolder.customerRequestId) return []

      return $httpFetch(
        '/bm/customer_request/:careFolderId/customer/discussion',
        {
          pathParams: {
            careFolderId: careFolder.customerRequestId,
          },
        },
      )
    })

    return {
      careFolder,
      claims,
    }
  })

  function pushMessage(message: CustomerConversationMessage) {
    const lastClaim =
      data.value?.claims && data.value.claims[data.value.claims.length - 1]

    if (lastClaim) {
      lastClaim.messages.push(message)
    }
  }

  if (error.value) {
    logger.error(
      '[CAPLA][CUSTOMER_CONVERSATION] Unable to fetch conversation information (careFolder or claims)',
      {
        error: error.value,
        owners: ['bot-squad-care-platform-front'],
      },
    )
  }

  return {
    data,
    pending,
    error,
    refresh,
    pushMessage,
  }
}
