export default {
  title: {
    id: 'sav_discussion_separator_falcon_return',
    defaultMessage: `{resolution, select,
      REFUND {Your client wants a refund}
      REPAIR {Your client wants a repair}
      REPAIR_REPLACE {Your client wants a repair or replacement}
      REPLACE {Express replacement request}
      SELLER_REPLACE {Your customer wants a replacement}
      SELLER_REPAIR {Your client wants a repair}
      SHOP_REPAIR {Your client wants a repair}
      other {
        New {count, plural,
            one {problem}
            other {problems}
          }{source, select,
            MOBILE_APP { from Back Market app}
            other {}
          }
      }
  }`,
  },
  backRepair: {
    id: 'sav_discussion_back_repair_tag',
    defaultMessage: 'BackRepair',
  },
  inShop: {
    id: 'sav_discussion_back_repair_in_shop_tag',
    defaultMessage: 'in-shop',
  },
  mailIn: {
    id: 'sav_discussion_back_repair_mail_in_tag',
    defaultMessage: 'mail-in',
  },
}
